import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    scores: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_STUDENTS_SCORES_LOADING: {
            const {
                classId,
                scoreType,
                dateFrom,
                dateTo,
            } = action.payload;

            const key = `${classId}-${scoreType}-${dateFrom}-${dateTo}`;
            const prevByKey = state.scores[key] || {};

            return {
                ...state,
                scores: {
                    ...state.scores,
                    [key]: {
                        ...prevByKey,
                        error: "",
                        isLoading: true,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_SCORES: {
            const {
                classId,
                scoreType,
                dateFrom,
                dateTo,
                data,
                error,
            } = action.payload;

            const key = `${classId}-${scoreType}-${dateFrom}-${dateTo}`;

            return {
                ...state,
                scores: {
                    ...state.scores,
                    [key]: {
                        data,
                        error,
                        isLoading: false,
                        isLoaded: true,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
