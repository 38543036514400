import * as types from "./types.js";


export const setScoresLoading = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_SCORES_LOADING,
    payload,
});

export const setScores = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_SCORES,
    payload,
});
