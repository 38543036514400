import Classes from "@/base/project/classes.js";

import text from "@/base/text/index.js";


const reloadClassScores = (services, params = {}) => {
    const { api, actions } = services;
    const { classId, scoreType, dateFrom, dateTo } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const { session } = state.user;

        const res = await api.teacherClass.getScores({
            session,
            classId,
            scoreType,
            dateFrom,
            dateTo,
        });

        let data = [
            {
                id: "",
            },
        ];

        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        // TODO: remove after tests
        data = null;
        error = "";

        dispatch(actions.teacherStudentsScores.setScores({
            classId,
            scoreType,
            dateFrom,
            dateTo,
            data,
            error,
        }));
    };
};

const loadOrReloadClassScoresByType = (services, params = {}) => {
    const scoreType = params.scoreType || "";
    const { actions } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const classId = state.teacher.selectedClassId;

        const { dateFrom, dateToOrLoadType } = state.navigation.params;

        if (!Classes.hasSelectedClass(classId)
            || !scoreType
            || !dateFrom
            || !dateToOrLoadType) {
            return;
        }

        const { scores } = state.teacherStudentsScores;

        const key = `${classId}-${scoreType}-${dateFrom}-${dateToOrLoadType}`;

        const actionParams = {
            classId,
            scoreType,
            dateFrom,
            dateTo: dateToOrLoadType,
        };

        if (!scores[key]?.isLoaded) {
            dispatch(actions.teacherStudentsScores.setScoresLoading(actionParams));
        }

        dispatch(reloadClassScores(services, actionParams));
    };
};


export default {
    loadOrReloadClassScoresByType,
};
